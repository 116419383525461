import { createNamespacedHelpers } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { Getters, Mutations, Actions, Guards } from '@/models/Guard.model';

// Inital state
const state: Guards = {
  guards: [],
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, Guards> = {
  guards: (state) => state.guards,
};

// TODO: Change mutation names to uppercase ( Standards )
// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, Guards> = {
  setGuards(state, { guards }) {
    state.guards = guards;
  },
};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, Guards, Mutations, Getters> = {
  setGuards({ commit }, payload) {
    commit('setGuards', payload);
  },
};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<Guards, Getters, Mutations, Actions>('GuardModule');

export const GuardsModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
