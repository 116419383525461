<template>
  <v-card
    class="v-card--wizard"
    elevation="12"
    max-width="800px"
  >
    <v-btn
      icon
      style="float:right"
      @click="$emit('click:cancel')"
      :disabled="loading"
    >
      <v-icon> {{ $store.getters['app/icons'].close}} </v-icon>
    </v-btn>

    <v-card-title 
      class="justify-center display-2 font-weight-light pt-5"
      style="word-break: normal"
      v-html="title"
    >
    </v-card-title>

    <v-tabs
      ref="tabs"
      v-model="internalValue"
      background-color="#EEE"
      show-arrows
      center-active
      light
      grow
    >
      <v-tab
        v-for="(item, i) in items"
        :key="i"
        :ripple="false"
        :disabled="isView ? false : internalValue != i"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <div class="my-6" />

    <v-card-text>
      <v-tabs-items 
        v-model="internalValue"
        touchless
      >
        <slot />
      </v-tabs-items>
    </v-card-text>

    <v-card-actions class="pb-4 pa-4" style="padding-top: 0px !important">
      <v-row class="pa-4">
        <v-btn
          v-if="internalValue !== 0"
          :disabled="loading"
          class="white--text mb-2 ml-auto"
          color="#777B7E"
          :width="$store.getters['app/maxWidthButton']($vuetify.breakpoint.name, ['xs'])"
          @click="goBack()"
        >
          Previous
        </v-btn>

        <v-spacer/>

        <v-btn
          v-if="(internalValue === items.length - 1) ? !isView : 'Next'"
          :disabled="isView ? false : !availableSteps.includes(internalValue + 1) || loading"
          color="primary"
          class="mb-2 ml-auto"
          :width="$store.getters['app/maxWidthButton']($vuetify.breakpoint.name, ['xs'])"
          @click="goNext()"
          :loading="loading"
        >
          {{ internalValue === items.length - 1 ? 'Save' : 'Next' }}

          <span
            slot="loader"
          >
            <v-progress-circular
              indeterminate
              :size="20"
              color="white"
            >
            </v-progress-circular>
          </span>
        </v-btn>
      </v-row>
    </v-card-actions>
    <div 
      v-if="!isView"
      style="margin-left: 3px"
      class="required-instruction"
    >
      * Required Field
    </div> 
  </v-card>
</template>

<script>
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable';

export default {
  name: 'CustomerWizard',

  mixins: [Proxyable],

  props: {
    title: {
      type: String,
      default: '',
    },
    availableSteps: {
      type: Array,
      default: () => ([]),
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    isView: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    goBack() {
      this.$emit('click:prev');
    },
    
    goNext() {
      this.$emit('click:next');
    },
  },
};
</script>

<style lang="sass">
.v-card--wizard
  .v-tabs-bar
    height: 48px

  .v-tab.v-tab--active
    color: white
    background-color: #00a4e3
  
</style>