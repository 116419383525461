// Imports
import Vue from 'vue';
import Router from 'vue-router';
import AuthService from '@/services/auth';

Vue.use(Router);

// create object router with the valid initialization
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes: [
    // --- LOGIN PAGE ---
    {
      path: '/login',
      component: () => import('@/layouts/page/Index.vue'),
      children: [
        {
          name: 'Login',
          path: '',
          component: () => import('@/views/Login.vue'),
        },
      ],
    },
    // --- LOGGED IN PAGES ---
    {
      path: '',
        beforeEnter: (to, from, next) => {
          if (to.matched.some((record) => record.meta.requiresAuth)) {
              // check to see if the user is logged in
              // just call the checkAuth method. It will handle things if User is not Authed.  
              AuthService.checkAuth().then((response) => {
                next();
              });
          } else {
            next();
          }
        },
        component: () => import('@/layouts/default/Index.vue'),
        children: [

          // --- OVERVIEW / DASHBOARD PAGE ---
          {
            name: 'Overview',
            path: '',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/views/Home.vue'),
          },
          {
            name: 'Estates',
            path: '/estates',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/views/Estates.vue'),
          },
          {
            name: 'Users',
            path: '/users',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/views/Users.vue'),
          },
          {
            name: 'Guard Listing',
            path: '/guards',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/views/Guards.vue'),
          },
          {
            name: 'Scan Hardware',
            path: '/scanHardware',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/views/ScanHardware.vue'),
          },
        ],

      },
    // --- ROUTES WILL GO HERE ---
    /*
      // Sample Routes we expect most of our projects will use
      // remove from comments as neccessary

      // --- FORGOTTEN PASSWORD PAGE ---
      {
        path: '/forgot',
        component: () => import('@/layouts/page/Index.vue'),
        children: [
          {
            name: 'Forgotten Password',
            path: '',
            component: () => import('@/views/Password_Forgot.vue'),
          },
        ],
      },

      // --- SET PASSWORD PAGE ---
      {
        path: '/X', // insert your own guid so no one can guess this URL - only access it via email link
        component: () => import('@/layouts/page/Index.vue'),
        children: [
          {
            name: 'Set Password',
            path: '',
            component: () => import('@/views/Password_Set.vue'),
            props: (route) => ({ username: route.query.un, token: route.query.token }),
          },
        ],
      },

      // --- LOGGED IN PAGES ---
      {
      path: '',
        beforeEnter: (to, from, next) => {
          if (to.matched.some((record) => record.meta.requiresAuth)) {
              // check to see if the user is logged in
              // just call the checkAuth method. It will handle things if User is not Authed.  
              AuthService.checkAuth().then((response) => {
                next();
              });
          } else {
            next();
          }
        },
        component: () => import('@/layouts/default/Index.vue'),
        children: [

          // --- USER PROFILE PAGE ---
          {
            name: 'User Profile',
            path: 'profile',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/views/UserProfile.vue'),
          },

          // --- OVERVIEW / DASHBOARD PAGE ---
          {
            name: 'Overview',
            path: '',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/views/Home.vue'),
          },
        ],
      },
    */
  ],
});

// export router as default
export default router;
