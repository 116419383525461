import { createNamespacedHelpers } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { Getters, Mutations, Actions, ScanHardwares } from '@/models/ScanHardware.model';

// Inital state
const state: ScanHardwares = {
  scanHardwares: [],
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, ScanHardwares> = {
  scanHardwares: (state) => state.scanHardwares,
};

// TODO: Change mutation names to uppercase ( Standards )
// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, ScanHardwares> = {
  setScanHardwares(state, { scanHardwares }) {
    state.scanHardwares = scanHardwares;
  },
};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, ScanHardwares, Mutations, Getters> = {
  setScanHardwares({ commit }, payload) {
    commit('setScanHardwares', payload);
  },
};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<ScanHardwares, Getters, Mutations, Actions>('ScanHardwareModule');

export const ScanHardwaresModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
