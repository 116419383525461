// Vue
import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import { AppModule } from './modules/app';
import { AuthModule } from './modules/auth';
import { EstatesModule } from './modules/estate';
import { UsersModule } from './modules/user';
import { GuardsModule } from './modules/guard';
import { ScanHardwaresModule } from './modules/scanHardware';

Vue.use(Vuex);

const store = new Vuex.Store<any>({
  modules: {
    app: AppModule,
    auth: AuthModule,
    estates: EstatesModule,
    users: UsersModule,
    guards: GuardsModule,
    scanHardwares: ScanHardwaresModule,
    genders: UsersModule,
    userTypes: UsersModule,
  },
});

export default store;
