import { createNamespacedHelpers } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { Getters, Mutations, Actions, Users } from '@/models/User.model';
import user from '@/services/user';

// Inital state
const state: Users = {
  loggedInUser: {
    loggedIn: false,
    userTypeId: 0,
    displayName: '',
  },
  users: [],
  userTypes: [],
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, Users> = {
  loggedInUser: (state) => state.loggedInUser,
  users: (state) => state.users,
  userTypes: (state) => state.userTypes,
};

// TODO: Change mutation names to uppercase ( Standards )
// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, Users> = {
  resetLoggedInUser(state) {
    state.loggedInUser = {
      loggedIn: false,
      userTypeId: 0,
      displayName: '',
    };
  },

  setLoggedInUser(state, { loggedInUser }) {
    state.loggedInUser = loggedInUser;
  },

  setUsers(state, { users }) {
    state.users = users;
  },

  setUserTypes(state, { userTypes }) {
    state.userTypes = userTypes;
  },
};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, Users, Mutations, Getters> = {
  resetLoggedInUser({ commit }, payload) {
    commit('resetLoggedInUser', payload);
  },

  setLoggedInUser({ commit }, payload) {
    commit('setLoggedInUser', payload);
  },
  
  setUsers({ commit }, payload) {
    commit('setUsers', payload);
  },

  setUserTypes({ commit }, payload) {
    commit('setUserTypes', payload);
  },
};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<Users, Getters, Mutations, Actions>('UserModule');

export const UsersModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
