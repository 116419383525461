/**
 *   This is a service for a specific store module which uses the api.ts Axios instance as a base.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import Api from '@/services/api';
import AppService from '@/services/app';
import Store from '@/store/index';
import Router from '@/router/index';

export default {

  async logSupportDebug(message: string) {
    if (!process.env.VUE_APP_ISLIVE) {
      console.log(`${new Date().toISOString().split('.')[0].replace('T', ' ')} - ` + message);
    }
  },

  login(params: { username: string; password: string }) {
    return Api().post('/login', params).then((response) => {
      // no need to handle failed login as go will return 401 - caught by api.ts    
      Store.dispatch('users/setLoggedInUser', {
        loggedInUser: {
          loggedIn: true,
        },
      });         
  
      return response;
    });
  },

  // Hit the check route and get the users details
  async checkAuth() {
    try {
      const response = await Api().get('/auth');
      if (response && response.data) {
        if (response.data.result && response.data.result === 'false') {
          Store.dispatch('users/resetLoggedInUser', {});
          return response.data.message;
        } else {     
          Store.dispatch('users/setLoggedInUser', {
            loggedInUser: {
              loggedIn: true,
              userTypeId: response.data.userTypeId,
              displayName: response.data.displayName,
            },
          });
          return '';
        }
      } else {
        // response is undefined or has no data field - SHOULD NEVER HAPPEN!
        throw new Error('response: ' + JSON.stringify(response));
      }

    } catch (error) {
      Store.dispatch('users/resetLoggedInUser', {});
      if (error.response) {
        return error.response.statusText;
      } else {
        await this.logSupportDebug('auth.ts - checkAuth - 63 - ' + error);
        return Store.getters['app/messages'].couldNotConnect;
      }
    }
  },

  // Hit the reset_password route and request password reset
  resetPassword(params: { id: number }) {
    return Api().post('/auth/reset_password', params);
  },

  logout() {
    // TODO empty the store
    Store.dispatch('users/resetLoggedInUser');
    
    Api().post('/logout').then((response) => {
      // redirect the user back to login
      AppService.successHandler(response.data.message);
      Router.push('/login');
    });
  },

};
