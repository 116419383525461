import Vue from 'vue';
import vuetify from './plugins/vuetify';
import './plugins';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import App from './App.vue';
import router from './router';
import store from './store';
import { sync } from 'vuex-router-sync';

Vue.config.productionTip = false;

sync(store, router);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');

console.log(`main.ts - 22 - ${new Date().toISOString().split('.')[0].replace('T', ' ')}`, 'I am running in LIVE mode: ' + process.env.VUE_APP_ISLIVE);

router.beforeEach((to, from, next) => {
  // console.log(`main.ts - 25 - to`, to);
  // console.log(`main.ts - 26 - from`, from);
  store.state.from = from.meta!.backLabel;
  next();
});
