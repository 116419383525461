// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const theme = {
  primary: '#00a4e3',
  secondary: '#1C1D21',
  accent: '#d4af37',  
  success: '#71BC78',
  info: '#1C1D21',
  warning: '#E69138',
  error: '#C01212',
};

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' },
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
