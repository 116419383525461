<template>
  <v-card
    v-bind="$attrs"
    :class="classes"
    class="v-card--material pa-3"
  >
    <div class="d-flex grow flex-wrap">      

      <v-sheet        
        :class="{
          'pa-7': !$slots.image
        }"
        :color="data.color"
        :max-height="data.icon ? 90 : undefined"
        :width="inline || data.icon ? 'auto' : '100%'"
        elevation="6"
        class="text-start v-card--material__heading mb-n6"
        dark
        rounded
      >

        <v-tooltip          
          bottom
          v-if="!data.loader"
        >
          <template 
              v-slot:activator="{ on }"
            >
            <v-icon 
              v-on="on"
              size="32"
              color="data.color"
            >
              {{ data.icon }}
            </v-icon>
          </template>
          <span>{{ data.tooltip }}</span>
        </v-tooltip>

        <div
          v-if="data.loader"
          style="padding: 6px"
        >
        <v-tooltip         
          bottom          
        >
          <template 
              v-slot:activator="{ on }"
            >
            <v-progress-circular                  
              indeterminate
              size="20"
              color="data.color"
              v-on="on"
            ></v-progress-circular>
          </template>
          <span>{{ data.tooltip }}</span>
        </v-tooltip>
        </div>

        <div
          v-if="text"
          class="headline font-weight-thin"
          v-text="text"
        />
      </v-sheet>

      <div
        v-if="$slots['after-heading']"
        class="ml-3"
      >
        <slot name="after-heading" />
      </div>

    </div>

    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2" />

      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
  export default {
    name: 'MaterialCard',

    props: {
      data: {
        type: Object,
        default: () => { 
          return {
            icon: '',
            color: '',
            tooltip: '',
            loader: false,
          };
        },
      },
      courier: {
        type: Number,
        default: 0,
      },
      hoverReveal: {
        type: Boolean,
        default: false,
      },
      image: {
        type: Boolean,
        default: false,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
    },

    computed: {
      classes() {
        return {
          'v-card--material--has-heading': this.hasHeading,
          'v-card--material--hover-reveal': this.hoverReveal,
        };
      },
      hasHeading() {
        return Boolean(this.$slots.heading || this.title || this.data.icon);
      },
      hasAltHeading() {
        return Boolean(this.$slots.heading || (this.title && this.data.icon));
      },
    },
  };
</script>

<style lang="sass">
  .v-card--material
    &__avatar
      position: relative
      top: -64px
      margin-bottom: -32px

    &__heading
      position: relative
      top: -40px
      transition: .3s ease
      z-index: 1

    &.v-card--material--hover-reveal:hover
      .v-card--material__heading
        transform: translateY(-40px)
</style>
